import Vue from 'vue'
import { ToastPlugin, ModalPlugin, IconsPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import VueDraggable from 'vuedraggable'
import VueTheMask from 'vue-the-mask'
import InfiniteLoading from 'vue-infinite-loading'

import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import { eventBus } from '@/libs/event-bus'
import vModelCustom from '@/directives/model-custom'

momentDurationFormatSetup(moment)
moment.locale('ru')
Vue.prototype.$moment = moment
Vue.prototype.$eventBus = eventBus

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueDraggable)
Vue.use(VueTheMask)
Vue.use(IconsPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(Vuesax)

Vue.directive('model-custom', vModelCustom)

Vue.use(InfiniteLoading, {
  slots: {
    // keep default styles
    noResults: '',
    // remove default styles
    noMore: '',
  },
})
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
